import * as React from "react"
import { graphql } from "gatsby"
import Footer from "../components/Footer"
import Layout from "../components/layout"
import Seo from "../components/seo"

import LargeX from "../images/large-x.svg"

import Markdown from "react-markdown"
import { download, sliceIntoChunks } from "../helpers/functions"

import Download from "../images/download.svg"
import ContentRenderer from "../components/ContentRenderer"

import Plus from "../images/ant-design_plus-outlined.svg"
import Minus from "../images/nextier-minus.svg"

const ProductTemplate = ({ data }) => {
  const product = data.allStrapiProduct.edges[0].node
  const specSheets = data.allStrapiSpecSheet.edges

  const applications = sliceIntoChunks(product.topapplications, 2)

  const quickQuide = product?.quickGuide?.url ?? false

  const [showMore, setShowMore] = React.useState(false)

  console.log("Spec sheets", specSheets)

  return (
    <Layout>
      <Seo title={product.Name} />
      <section className="nextier-product-hero">
        <div className="blue-line-container">
          <div className="container nextier-product-hero__inner">
            <div className="content-container">
              <h1 className="nextier-product-hero__title">{product.Name}</h1>
              <p className="nextier-product-hero__subtitle">
                {product.longname}
              </p>
              <div className="mb-5">
                <Markdown children={product.Description} />
              </div>
            </div>
            <div className="nextier-product-hero__details-container">
              <h2 className="nextier-product-hero__title">top applications</h2>
              {applications.map((group, i) => (
                <div key={i} className="blue-line">
                  {group.map(application => (
                    <p key={application.Name}>{application.Name}</p>
                  ))}
                </div>
              ))}
              {quickQuide && (
                <a className="download-btn_alt mt-6" href={quickQuide}>
                  View {product.Name} Quick Guide
                </a>
              )}
            </div>
          </div>
        </div>
        <img
          src={product.Image.url}
          alt={product.Image.alternativeText}
          className="nextier-product-hero__image"
        />
        <img src={LargeX} className="nextier-product-hero__background" />
      </section>

      {specSheets.length > 0 && (
        <section className="product-specsheets">
          <div className="blue-line-container">
            <div className="container product-specsheets__inner">
              <h2>product spec sheets</h2>
              <div className="content-container">
                <table className="specsheets__table">
                  <thead>
                    <tr>
                      <th className="specsheets__heading">Product</th>
                      <th className="specsheets__heading">Classification</th>
                      <th className="specsheets__heading">Features</th>
                      <th className="specsheets__heading">Uses</th>
                      <th className="specsheets__heading"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {specSheets.map((sheet, i) => {
                      const { node } = sheet

                      if (i > 4 && !showMore) {
                        return false
                      }

                      return (
                        <tr className="specsheets__row">
                          <td className="specsheets__cell">
                            <span className="specsheets__cell-title">
                              Product
                            </span>
                            <span className="specsheets__cell-text">
                              {node.Name}
                            </span>
                          </td>
                          <td className="specsheets__cell">
                            <span className="specsheets__cell-title">
                              Classification
                            </span>
                            <span className="specsheets__cell-text">
                              {node.Classification}
                            </span>
                          </td>
                          <td className="specsheets__cell ">
                            <span className="specsheets__cell-title">
                              Features
                            </span>
                            <span className="specsheets__cell-text">
                              {node.Features}
                            </span>
                          </td>
                          <td className="specsheets__cell">
                            <span className="specsheets__cell-title">Uses</span>
                            <span className="specsheets__cell-text">
                              {node.Uses}
                            </span>
                          </td>
                          <td className="specsheets__download">
                            {node.File?.url ? (
                              <a
                                href={node.File.url}
                                onClick={e => {
                                  e.preventDefault()
                                  download(node.File.url, node.File.name)
                                }}
                                download={node.File.name}
                              >
                                <span className="specsheets__download-text">
                                  Download
                                </span>
                                <img
                                  className="specsheets__download-icon"
                                  src={Download}
                                  alt="Download"
                                />
                              </a>
                            ) : (
                              <a href={node.Link}>
                                <span className="specsheets__download-text">
                                  Download
                                </span>
                                <img
                                  className="specsheets__download-icon"
                                  src={Download}
                                  alt="Download"
                                />
                              </a>
                            )}
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>

                {specSheets.length > 5 && (
                  <button
                    onClick={() => setShowMore(!showMore)}
                    className={`download-btn mt-6 see-more ${
                      showMore ? "open" : ""
                    }`}
                  >
                    <span>{showMore ? "Collapse" : "See All"}</span>
                    {showMore ? (
                      <span>
                        <img src={Minus} />
                      </span>
                    ) : (
                      <span>
                        <img src={Plus} />
                      </span>
                    )}
                  </button>
                )}
              </div>
            </div>
          </div>
        </section>
      )}

      <ContentRenderer zone={product.dyanmiczone} />

      <Footer />
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    allStrapiProduct(filter: { slug: { eq: $slug } }) {
      edges {
        node {
          slug
          Description
          Image {
            alternativeText
            url
          }
          Name
          dyanmiczone
          longname
          quickGuide {
            url
          }
          topapplications {
            Name
            id
          }
        }
      }
    }
    allStrapiSpecSheet(filter: { product: { slug: { eq: $slug } } }) {
      edges {
        node {
          Link
          Name
          Uses
          Classification
          Features
          File {
            url
            name
          }
        }
      }
    }
  }
`

export default ProductTemplate
